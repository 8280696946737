@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
$font1: 'PT Sans', sans-serif;
$font: $font1;

$color1: #343c4c;
$dark: $color1;
$text: $color1;

$color2: #4884fa;
$blue: $color2;

$xxlarge: 1920px; // 120rem
$xlarge: 1600px; // 100rem
$large: 1440px; // 90rem
$xxmedium: 1080px; // 67.5rem
$xmedium: 968px; // 60.5
$medium: 800px; // 50rem
$small: 640px; // 40rem
$xsmall: 480px; // 23.4375
$xxsmall: 375px; // 23.4375
$xxxsmall: 320px; // 20

// $column-width: 240px;
// $column-gutter: 30px;

// @media screen and (max-width: $xxmedium) {}
// @media screen and (max-width: $xmedium) {}
// @media screen and (max-width: $medium) {}
// @media screen and (max-width: $small) {}

// 14 0.875
// 16 1
// 20 1.25
// 24 1.5

@mixin hidden {
  visibility: hidden;
  z-index: -1;
  opacity: 0;
  // pointer-events: none;
}

@mixin visible {
  visibility: visible;
  opacity: 1;
  z-index: 100;
}

@mixin button {
  outline: none;
  box-shadow: none;
}

@mixin transparent {
  outline: none;
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding: 0;
}

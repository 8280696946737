body {
  font-family: $font;
  // font-size: 100%;
  font-size: 1em;
  line-height: 1.4;
  font-weight: 400;
  color: $text;
  @media screen and (max-width: $medium) {
  }
  &.noscroll {
    overflow: hidden;
  }
}
.container {
  width: 100%;
  max-width: $xxmedium;
  margin: 0 auto;
  padding-left: 2em;
  padding-right: 2em;
  &--narrow {
    max-width: 25em;
  }
}
.page {
  padding: 2em;
  & > p {
    &:first-child {
      margin-top: 0;
    }
    font-size: 1.25em;
    font-weight: bold;
    & > a {
      border-bottom: 1px solid;
      &:hover {
        border-color: transparent;
      }
    }
  }
}
